import React, { useState, useData } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPizzaSlice } from "@fortawesome/free-solid-svg-icons";

function FoodCategory({ category }) {
  return (
    <div style={{ display: "block", padding: 10 }}>
      <Row
        style={{ border: "dotted", display: "flex", backgroundColor: "black" }}
      >
        <Col md={8}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                paddingTop: "0px",
                paddingRight: "40px",
                paddingBottom: "0px",
                paddingLeft: "0px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  paddingTop: "0px",
                  paddingRight: "40px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  fontSize: "28px",
                  fontFamily: "Kaushan Script",
                  color: "white",
                }}
              >
                {category.name}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default FoodCategory;
