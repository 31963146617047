import React from 'react';
import { Row, Col } from 'react-bootstrap';
import image1 from './../assets/images/banner.jpg';
 const HeaderComponent = () => {
    return (
        <div>
            <Row style={{ display: 'flex', backgroundColor: ''}}>
            <Col md={12}>
                <div style={{ paddingTop: "0px", paddingRight: "0px", paddingBottom: "0px", paddingLeft: "0px",fontSize: "20px", backgroundColor: "red"}} ><img
                  className="d-block w-100"
                  src={image1}
                  alt="First slide"/></div>
                
                </Col>                
          </Row>
            
    </div>

    )
}
export default HeaderComponent